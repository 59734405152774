import React from "react";
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import _ from "lodash"
import styled from "styled-components"
import { device } from "../styles/breakPoints"

const PostCardStyles = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-flow: column;
  cursor: default;
  overflow: hidden;
  padding-bottom: 3rem;
  border-radius: 3px; 
  background-color: var(--color-post-card);
  margin-bottom: 3rem;
  p {
    margin-bottom: 0em;
    margin-top: 0.75em;
  }
  img {
    width: 100%;
    object-fit: cover;
  }
  @media ${device.mobile} {
    height: auto;
  }
`

const PostCardLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &.post-readmore-icon {
    display: inline-block;
    margin-left: 0.5rem;
    .post-card-readmore {
      display: flex;
      justify-content: flex-end;
      svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: var(--color-font);
        &:hover {
          fill:  var(--color-purple);
        }
      }
    }
  }
`
const PostCardContent = styled.div`
  padding: 1.5em;
  flex-grow: 1;
  min-height: 150px;
  height: 100%;
  text-align: center;
`

const PostCardTitle = styled.h1`
  font-size: 1.2em;
  font-weight: 600;
  margin-top: 0em;
  margin-bottom: 0em;
  letter-spacing: .005em;
  word-spacing: -.002em;
  line-height: 1.34;
  text-decoration: none;
  color: var(--font-color);
`

const PostCardDescription = styled.div`
  p {
    font-size: 1.4rem;
    line-height: 1.6;
  }
`
const PostAuthor = styled.p`
  font-size: 1.2rem;
  color: var(--color-gray-1);
`

const PostCardMeta = styled.div`
  color: var(--color-gray-1);
  font-weight: 600;
  font-size: .9rem;
  letter-spacing: .3rem;
  line-height: 2.1;
  text-transform: uppercase;
  margin-bottom: 1em;
`
const PostCardDate = styled.span`
  font-weight: 600;
  font-size: .9rem;
  letter-spacing: .3rem;
  line-height: 2.1;
  text-transform: uppercase;
  color: var(--color-secondary);
`
const PostCardCategory = styled.span`
  letter-spacing: .01em;
  display: block;
  color: var(--color-black-1);
  a {
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  /* &::after {
    display: inline-block;
    position: relative;
    top: .2rem;
    content: "";
    width: 1px;
    background-color: var(--color-gray-1);
    height: 1.1rem;
    margin: 0 .8rem 0 1.1rem;
  } */
`
const PostCardImage = styled(GatsbyImage)`
  height: 220px;
  width: 100%;
`

function PostCard(props) {
  return (
    <PostCardStyles key={props.node.fields.slug} className="post-card-wrapper">
      <PostCardLink to={props.node.fields.slug}>
        {props.node.frontmatter.thumbnail &&
          <PostCardImage
            alt={props.node.frontmatter.thumbnail.childImageSharp.fluid.originalName}
            image={props.node.frontmatter.thumbnail.childImageSharp.gatsbyImageData}
          />
        }
      </PostCardLink>
        <PostCardContent>
          <PostCardMeta>
            <PostCardCategory>
              <Link to={`/categories/${_.kebabCase(props.node.frontmatter.categories[0])}`}>
                {props.node.frontmatter.categories[0].toUpperCase()}
              </Link>
            </PostCardCategory>
            <PostCardDate>{props.node.frontmatter.date}</PostCardDate>
          </PostCardMeta>
          <PostCardLink to={props.node.fields.slug}>
            <PostCardTitle>
              {props.node.frontmatter.title || props.node.fields.slug}
            </PostCardTitle>
            {/* <PostCardDescription>
              <p
              dangerouslySetInnerHTML={{
                __html: props.node.frontmatter.description || props.node.excerpt,
              }}
              />
            </PostCardDescription> */}
            <PostAuthor>By Tenzin Noryang</PostAuthor>
          </PostCardLink>
        </PostCardContent>
    </PostCardStyles>
  );
}

export default PostCard;
