import React from "react";
import styled from "styled-components"
import { device } from "../styles/breakPoints"

const InnerPaddingDiv = styled.div`
    padding: 0 70px;
    @media ${device.mobile} {
        padding: 0 25px;
    }
`

const InnerPadding = (props) => {
    const {children} = props;
    return (
        <InnerPaddingDiv>
            {children}
        </InnerPaddingDiv>
    );
}

export default InnerPadding;