import React from "react"
import { Link, graphql } from "gatsby"
import Masonry from "react-masonry-css"
import styled from "styled-components"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import PostCard from "../components/PostCard"
import InnerPadding from "../components/InnerPadding"
import { device } from "../styles/breakPoints"

const PaginateButton = styled(Link)`
  background-color: var(--color-black-2);
  padding: 1.5rem 6rem;
  color: var(--color-gray-6);
  text-decoration: none;
  &:hover {
    background-color: var(--color-black-1);
  }
`
const HeroSection = styled.section`
  display: flex;
  color: #fff;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto 7rem auto;
  justify-content: space-evenly;
  position: relative;
  padding: 2rem;
  @media ${device.mobile} {
    flex-direction: column;
    .hero-text-container {
      text-align: center;
    }
  }
  .bg-layer {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: top;
    z-index: 5;
  }
  .hero-text-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 10;
    h2 {
      font-size: 4rem;
      color: var(--color-secondary);
    }
    p {
      color: var(--color-font);
      font-size: 1.8rem;
    }
  }
  .hero-image-container {
    display: flex;
    align-items: center;
    background-color: var(--color-post-card);
    border-radius: 3px;
    flex-direction: column;
    padding: 2em;
    z-index: 10;
    .cover {
      clip-path: ellipse(45% 45% at 50% 50%);
      display: block;
      height: 300px;
      width: 300px;
      position: relative;
      transition: all .5s cubic-bezier(.67,0,.285,1);
  
      .hero-image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all 1s cubic-bezier(.67,0,.285,1),opacity 0s linear;
      }
    }
    .profile {
      text-align: center;
      p {
        color: var(--color-secondary);
        font-size: 1.4rem;
        margin-top: 1rem;
      }
    }
    @media ${device.tablet} {
      order: 1;
    }
  }
`

const PostsSection = styled.section`
  max-width: 1140px;
  margin: 0 auto;
`

class BlogIndexTemplate extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.posts.edges
    const postPerPage = 6
    const currentPage = this.props.pageContext.index / postPerPage + 1
    const lastPage = parseInt((this.props.pageContext.postCount - 1) /postPerPage + 1)
    const prevPage = currentPage - 1
    const nextPage = currentPage + 1
    const breakpointColsObj = {
      default: 3,
      900: 2,
      600: 1
    }
    const home = data.home.edges[0].node;
        
    return (
      <Layout title={siteTitle}>
        <Seo title="Homepage" />
        {currentPage === 1 
          ? <HeroSection>
              <div className="bg-layer" style={{ backgroundImage: `url(${home.frontmatter.backgroundImage})` }}></div>
              <div className="hero-text-container">
                <h2>{home.frontmatter.title}</h2>
                {/* <p>{home.frontmatter.intro}</p> */}
              </div>
              <div className="hero-image-container">
                <span className="cover">
                  <img 
                    className="hero-image"
                    src={home.frontmatter.heroImage} />
                </span>
                <div className="profile">
                  <h3>Tenzin Noryang</h3>
                  <p>Leader. Mother. Creative.</p>
                </div>
              </div>
            </HeroSection>
          : <></>}
        <InnerPadding>
          <PostsSection>
            <Masonry 
            breakpointCols={breakpointColsObj}
              className="masonry-posts-wrapper"
              columnClassName="masonry-post-column">
              {posts.map(({ node }) => {
                  return (
                    <PostCard
                      key={node.fields.slug}
                      node={node}
                      postClass={`post`}
                    />
                  )
              })}
            </Masonry>
            {lastPage === 1 || 
            <div className="pagenator">
              {currentPage === 1
                ? <div className="pagenator-unlinked">
                    <svg transform="scale(-1, 1)" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000" xmlSpace="preserve">
                      <g><path d="M767.9,499.9L291.6,10l-59.4,61.3l416.6,428.7L232.1,928.7l59.5,61.3L767.9,499.9z"/></g>
                    </svg> 
                  </div>
                : <PaginateButton 
                    className="pagenator-link" 
                    to={prevPage === 1 ?`/` :`/` + prevPage}
                  >
                    Newer Posts
                  </PaginateButton>}
              {currentPage === lastPage
                ? <div className="pagenator-unlinked">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000" xmlSpace="preserve">
                      <g><path d="M767.9,499.9L291.6,10l-59.4,61.3l416.6,428.7L232.1,928.7l59.5,61.3L767.9,499.9z"/></g>
                    </svg>
                  </div>
                : <PaginateButton 
                    className="pagenator-link" 
                    to={`/` + nextPage}
                  >
                    Older Posts
                  </PaginateButton>}
            </div>}
          </PostsSection>
        </InnerPadding>
      </Layout>
    )
  }
}

export default BlogIndexTemplate;

export const pageQuery = graphql`
  query BlogIndexByIndex($index: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    posts: allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}, limit: 6, skip: $index, filter: {frontmatter: {templateKey: {eq: "blog"}}}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            categories
            description
            thumbnail {
              childImageSharp {
                gatsbyImageData(height: 250)
                fluid {
                  originalName
                }
              }
            }
          }
        }
      }
    }
    home: allMarkdownRemark(filter: {  frontmatter: { templateKey: { eq: "home-page" }}}) {
      edges {
        node {
          frontmatter {
            title
            heroImage
            intro
            backgroundImage
          }
        }
      }
    }
  }
`
